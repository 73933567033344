import { Controller, useForm } from "react-hook-form";

import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";

import Navbar from "../../shared/Navbar/Navbar";

import ErrorMessage from "../../shared/BaseFormComponents/ErrorMessage";
import "../style.css";
import AddCourseAutocomplete from "../../../components/AddCourseAutocomplete";

import useLogRound from "../hooks/functions";
import { validationErrorMap } from "../utils/constants";

import { useEffect, useState, useRef, useMemo } from "react";
import moment from "moment";
import { Popover } from "bootstrap";
import ScoreCardModal from "../components/ScoreCardModal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { searchCourses } from "../../course/courseActions";
import { setLoading } from "../../user/userSlice";
import ConfirmClearScoreCardModal from "../components/ConfirmClearScoreCardModal";
import _, { set } from "lodash";
import ModalDialog from "../../shared/ModalDialog";
import { deleteRoundAction } from "../roundActions";


function computeFullyMappedTees(selectedCourse) {
  if (!selectedCourse) return [];
  const fullyMappedTeeNames = selectedCourse.mappedTeesCount;
  if (!fullyMappedTeeNames) return [];
  const tees = Object.values(selectedCourse.tees).filter((tee) => {
    return fullyMappedTeeNames.includes(tee.name);
  });
  return tees;
}

const LogRound = () => {
  const {
    isNew,
    methods,
    onSubmit,
    sgsHandler,
    distanceUnit,
    formatDistance,
    distanceUnitHandler,
    distanceUnitKeyHandler,
    closeLogPage,
    holeByHole,
    setHoleByHole,
    user,
    round,
    setValue,
    getValues,
    watch,
    rounds,
    fetchCourseDetailsCallBack,
    cleanUpScoreCard,
    validateSeconds,
    clearScorecardTime,
    navigate,
    index: roundIndex
  } = useLogRound();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const dispatch = useDispatch();

  const [selectedCourse, setSelectedCourse] = useState(null);
  const course = watch("course");

  const [selectedTee, setSelectedTee] = useState(null);
  const [outData, setOutData] = useState({});
  const [inData, setInData] = useState({});
  const [totalData, setTotalData] = useState({});
  const [showHoleTimes, setShowHoleTimes] = useState(false);
  const [strokesComplete, setStrokesComplete] = useState(false);
  const [timesComplete, setTimesComplete] = useState(false);
  // const coursesDB = JSON.parse(localStorage.getItem("courses"));
  const coursesDB = useSelector((state) => state.courses);
  const [showScorecardWarning, setShowscorecardWarning] = useState(false);
  // const playerParGender = useSelector(state => state.user.user.personalInfo.parGender)
  const [deleteDialogId, setDeleteDialogId] = useState(null);
  const [fullyMappedTees, setFullyMappedTees] = useState(computeFullyMappedTees(selectedCourse));

  const location = useLocation();

  const deleteRoundHandler = index => {
    dispatch(deleteRoundAction(index, navigate));
  };

  const checkIfStrokesAndTimeCompleteAndGetOldValues = () => {
    const oldMinutes = getValues("minutes");
    const oldSeconds = getValues("seconds");
    const oldStrokes = getValues("strokes");
    let strokesEmpty = true;
    let minutesEmpty = true;
    let secondsEmpty = true;
    for (let i = 0; i < holeByHole?.length; i++) {
      if (holeByHole[i].strokes !== null && holeByHole[i].strokes !== null && holeByHole[i].strokes > 0) {
        strokesEmpty = false;
      }
      if (holeByHole[i].minutes !== null && holeByHole[i].minutes !== "" && holeByHole[i].minutes > 0) {
        minutesEmpty = false;
      }
      if (holeByHole[i].seconds !== null && holeByHole[i].seconds !== "" && holeByHole[i].seconds > 0) {
        secondsEmpty = false;
      }
    }
    return { strokesEmpty, minutesEmpty, secondsEmpty, oldMinutes, oldSeconds, oldStrokes };
  };

  const setInitialTotalStrokesData = () => {
    const { strokesEmpty, minutesEmpty, secondsEmpty, oldMinutes, oldSeconds, oldStrokes } =
      checkIfStrokesAndTimeCompleteAndGetOldValues();
    const data = {
      out: calculateTotalValues("OUT", holeByHole),
      in: calculateTotalValues("IN", holeByHole),
      total: calculateTotalValues("TOTAL", holeByHole),
    };
    if (strokesEmpty) {
      data.total.strokes = oldStrokes;
    }
    if (minutesEmpty && secondsEmpty) {
      data.total.minutes = oldMinutes;
      data.total.seconds = oldSeconds;
    }
    return data;
  };

  // const [totalStrokesData, setTotalStrokesData] = useState({ out: {}, in: {}, total: {} });
  const [totalStrokesData, setTotalStrokesData] = useState(setInitialTotalStrokesData());

  const [roundStartTime, setRoundStartTime] = useState(holeByHole ? holeByHole[0]?.startTime : null);
  const [beforeSaveRoundData, setBeforeSaveRoundData] = useState(null);
  const popoverRef = useRef(null);

  const checkIfStrokesAndTimeComplete = () => {
    let strokesComplete = true;
    let timeComplete = true;
    for (let i = 0; i < holeByHole?.length; i++) {
      if (typeof holeByHole[i].strokes != "number") {
        strokesComplete = false;
      }
    }
    // if(!holeByHole[holeByHole?.length - 1]["finishTime"]) timeComplete = false;
    // Removing time complete check for now as is not mandatory field
    if (!holeByHole[holeByHole?.length - 1]["elapsedTime"]) timeComplete = false;
    return strokesComplete;
  };

  // const [scorecardButtonEnable, setScoreCardButtonEnable] = useState(false);
  const scoreCardEnableStatus = checkIfStrokesAndTimeComplete();
  const [scorecardButtonEnable, setScoreCardButtonEnable] = useState(scoreCardEnableStatus);
  // console.log('Strokes value: ', watch('strokes'))

  const handleUncheckScorecard = () => {
    const userConfirmed = window.confirm("Are you sure? This will delete the scorecard data");
    if (userConfirmed) {
      setScoreCardButtonEnable((prevState) => !prevState);
      cleanUpScoreCard();
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedTee)) {
      console.log("see", selectedTee);
      setValue("tee", selectedTee);
      setOutData(calculateTotalValues("OUT", selectedTee.holes));
      setInData(calculateTotalValues("IN", selectedTee.holes));
      setTotalData(calculateTotalValues("TOTAL", selectedTee.holes));
    }
  }, [selectedTee]);

  const checkStokesComplete = () => {
    console.log("here line 68");
    if (scorecardButtonEnable) {
      // ? why are we adding strokes if scorecardButtonis enabled?
      // * ANS to above: this function gets executed once when checkbox is clicked and when holyByhole is changed
      // * We need this logic when hole by hole is changed
      setValue("strokes", totalStrokesData?.total["strokes"]);
    }
    for (let i = 0; i < holeByHole?.length; i++) {
      if (typeof holeByHole[i].strokes != "number") {
        setStrokesComplete(false);
        return;
      }
    }
    setStrokesComplete(true);
  };

  useEffect(() => {
    // if (!isNew) {
    //   Object.keys(coursesDB).forEach((c) => {
    //     if (coursesDB[c].id === round?.courseId.id) {
    //       setSelectedCourse(coursesDB[c]);
    //       setValue("course", coursesDB[c].shortName);
    //       const selectedTeeId = round?.teeId;
    //       // setSelectedTee(round?.tee);
    //       const selectedTeeObject = Object.values(coursesDB[c].tees).find((tee) => tee._id === selectedTeeId)
    //       setSelectedTee(selectedTeeObject);
    //     }
    //   });
    // }

    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.map((popoverTriggerEl) => {
      return new Popover(popoverTriggerEl);
    });

    const popover = new Popover(popoverRef.current, {
      trigger: "focus",
    });

    const handleOutsideClick = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !document.querySelector(".popover")?.contains(event.target)
      ) {
        popover.hide();
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      // Cleanup
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  /**
   * Courses DB updates 2 times, the courses text box is a big componenet that fetches all courses data when this component loads
   * then, we again hit the database to fetch tees info for the selected course in edit mode
   * during this call the old course list is removed and the new course item is added to store
   * this new course item is a singular course that is fetched by ID from rounds info. Hence, we can use ZERO index to reference that course item
   * The reference for this can be found in functions.js file
   * ! We might need to think of a better logic to handle this in future!
   *
   * UPDATE: for now commenting the above logic,
   * since we might need to use the old course list for auto update in courses input box
   * If we remove that data, it could cause in-consistancyes in suggestions.
   */
  useEffect(() => {
    if (isNew) return;
    // const selectedCourseFromDB = coursesDB.find((course) => course.id === round?.courseId.id);
    const selectedCourseFromDB = coursesDB.find((courseItem) => courseItem.shortName === course);
    // if (!isNew && coursesDB.length == 1 && coursesDB[0].id === round?.courseId.id) {
    //   setSelectedCourse(coursesDB[0]);
    //   setValue("course", coursesDB[0].shortName);
    //   const selectedTeeId = round?.teeId;
    //   const selectedTeeObject = Object.values(coursesDB[0].tees).find((tee) => tee._id === selectedTeeId)
    //   setSelectedTee(selectedTeeObject);
    // }
    if (
      !isNew &&
      selectedCourseFromDB /*selectedCourseFromDB.id === round?.courseId.id && (This condition does not work if we are updating the round*/
    ) {
      setSelectedCourse(selectedCourseFromDB);
      setValue("course", selectedCourseFromDB.shortName);
      if (!Array.isArray(selectedCourseFromDB.tees)) {
        const selectedTeeId = round?.teeId;
        const selectedTeeObject = Object.values(selectedCourseFromDB.tees).find((tee) => tee._id === selectedTeeId);
        // if no tee, then select the first tee as the selected tee, given we have tees
        if (selectedTeeObject) setSelectedTee(selectedTeeObject);
        // TODO: below 2 lines of code working fine before adding tee name in round table
        // else if (!selectedTeeObject && Object.values(selectedCourseFromDB.tees).length > 0)
        //   setSelectedTee(Object.values(selectedCourseFromDB.tees)[0]);
        else if (!selectedTeeObject && Object.values(selectedCourseFromDB.tees).length > 0 && fullyMappedTees.length > 0) { 
          setSelectedTee(fullyMappedTees[0]);
        }
        else setSelectedTee(null);
      } else setSelectedTee(null);
    }
  }, [course, coursesDB]);

  /**
   * USE effect to handle new round and the above use effect is to handle old round
   * There is a use effect in functions.js which sets full tees data for the selected course via an async thunk
   * This useEffect is dependent on the coursesDB, hence we run it and set the selectedCourse fetched from the backend
   */
  useEffect(() => {
    if (isNew && course && coursesDB) {
      const item = coursesDB.find((courseItem) => courseItem.shortName === course);
      setSelectedCourse(item);
      if (item?.tees && Object.keys(item?.tees).length > 0 && fullyMappedTees.length > 0) {
        // setSelectedTee(Object.values(item.tees)[0]);
        setSelectedTee(fullyMappedTees[0]);
      } else {
        setSelectedTee(null);
      }
    }
  }, [course, coursesDB, fullyMappedTees]);

  /**
   * @param {boolean} checkBoxChecked this is a boolean value to check if the checkbox is checked or not for the score card
   * @returns void
   */
  const checkTimeComplete = (checkBoxChecked) => {
    // if (holeByHole[holeByHole?.length - 1]["finishTime"]) {
    //   let timeValue = moment
    //     .duration(
    //       moment(holeByHole[holeByHole?.length - 1]["finishTime"], "HH:mm:ss").diff(moment(roundStartTime, "HH:mm:ss"))
    //     )
    //     .asSeconds();
    //   setValue("times", timeValue);
    //   setValue("minutes", parseInt(timeValue / 60));
    //   setValue("seconds", (timeValue % 60).toString()?.padStart(2, 0));
    //   setTimesComplete(true);
    // } else if (scorecardButtonEnable) {
    //   setValue("times", 0);
    //   setValue("minutes", 0);
    //   setValue("seconds", (0 % 60).toString()?.padStart(2, 0));
    // }
    let times = 0;
    let totalMinutes = 0;
    let totalSeconds = 0;
    let holeElapsedTime = new Array(18).fill(null);
    // holeByHole.forEach((hole) => {
    //   if (parseInt(hole.minutes) < 1 || parseInt(hole.minutes) > 390) {
    //     setTimesComplete(false);
    //     minutes = 0;
    //   }
    // })

    if (scorecardButtonEnable) {
      // setValue("times", 0);
      setValue("minutes", totalStrokesData.total.minutes);
      setValue("seconds", totalStrokesData.total.seconds);
      setValue("times", totalStrokesData.total.minutes * 60 + totalStrokesData.total.seconds);
    }

    for (let i = 0; i < holeByHole?.length; i++) {
      const currentMinutes = holeByHole[i].minutes;
      const currentSeconds = holeByHole[i].seconds;
      if (
        !currentMinutes ||
        !currentSeconds ||
        currentMinutes < 1 ||
        currentMinutes > 390 ||
        currentSeconds < 0 ||
        currentSeconds > 59
      ) {
        // setValue("times", 0);
        // setValue("minutes", 0);
        // setValue("seconds", (0 % 60).toString()?.padStart(2, 0));
        setTimesComplete(false);
        // return;
        holeElapsedTime[i] = null;
        continue;
      }
      totalMinutes += parseInt(currentMinutes);
      totalSeconds += parseInt(currentSeconds);
      holeElapsedTime[i] = parseInt(currentMinutes) * 60 + parseInt(currentSeconds);
      times = totalMinutes * 60 + totalSeconds;
      // setValue("times", times);
      // setValue("minutes", totalMinutes);
      // setValue("seconds", totalSeconds.toString().padStart(2, 0));
    }

    setHoleByHole((prevData) => {
      prevData.forEach((hole, index) => {
        hole.elapsedTime = holeElapsedTime[index];
      });
      return prevData;
    });

    // TODO: The socrecardButtonEnable is not being used in the function. Need to check if it is needed or not
    // if (!checkBoxChecked) {
    //   setValue("times", 0);
    //   setValue("strokes", 80);
    //   setValue("minutes", 60);
    //   setValue("seconds", (0 % 60).toString()?.padStart(2, 0));
    // }
    return;
  };

  /**
   * This function is executed when ever hole by hole data is changed
   * TODO: This is repeated code, need to clean up in future
   * TODO: Updating the state again, need to clean up!
   */
  const updateGolfRoundMetrics = () => {
    const oldTimes = getValues("times");
    const { strokesEmpty, minutesEmpty, secondsEmpty, oldMinutes, oldSeconds, oldStrokes } =
      checkIfStrokesAndTimeCompleteAndGetOldValues();
    setTotalStrokesData((prevData) => ({ ...prevData, out: calculateTotalValues("OUT", holeByHole) }));
    setTotalStrokesData((prevData) => ({ ...prevData, in: calculateTotalValues("IN", holeByHole) }));
    setTotalStrokesData((prevData) => ({ ...prevData, total: calculateTotalValues("TOTAL", holeByHole) }));
    if (strokesEmpty) {
      setTotalStrokesData((prevData) => {
        const clonedState = _.cloneDeep(prevData);
        clonedState.total.strokes = getValues("strokes");
        return clonedState;
      });
    }
    if (minutesEmpty && secondsEmpty) {
      setTotalStrokesData((prevData) => {
        const clonedState = _.cloneDeep(prevData);
        clonedState.total.minutes = oldMinutes;
        clonedState.total.seconds = oldSeconds;
        return clonedState;
      });
    }
    checkStokesComplete();
    checkTimeComplete();
    sgsHandler();
  };

  // useEffect(() => {
  //   checkStokesComplete();
  //   checkTimeComplete();
  //   sgsHandler();
  // }, [totalStrokesData])

  // console.log("times complete", timesComplete);
  // console.log("Watch minutes: ", watch("minutes"), totalStrokesData);

  useEffect(() => {
    updateGolfRoundMetrics();
  }, [holeByHole]);

  // *** TESTED CODE. NEED TO CLEAN UP IN FUTURE
  // setSelectedCourse(coursesDB.find((course) => course.shortName === item?.name));
  // const pathsToCheck = ['newround', 'editround'];
  // const isPathIncluded = pathsToCheck.some(path => location.pathname.toLowerCase().includes(path));
  // if (isPathIncluded && item?.name || undefined) {
  //   await dispatch(searchCourses({ searchString: item.name.split(',')[0], category: 'Name', limit: 1 }, navigate));
  //   setSelectedCourse(coursesDB[0]);
  // }

  async function setData(item) {
    // setSelectedCourse(item);
    setValue("course", item?.name || "");
    // setSelectedCourseName(item?.name || "");
    // const courseName = item?.name || "";
    // if (item?.tees && Object.keys(item?.tees).length > 0) {
    //   setSelectedTee(Object.values(item.tees)[0]);
    // } else {
    //   setSelectedTee(null);
    // }
  }

  function calculateTotalValues(tag, data) {
    // console.log("here");
    let total = {};
    if (tag == "OUT") {
      data.slice(0, 9).forEach((obj) => {
        for (const key in obj) {
          if (!isNaN(obj[key])) {
            total[key] = (total[key] || 0) + (parseInt(obj[key]) || 0);
          }
        }
      });
    } else if (tag == "IN") {
      data.slice(9, 18).forEach((obj) => {
        for (const key in obj) {
          if (!isNaN(obj[key])) {
            total[key] = (total[key] || 0) + (parseInt(obj[key]) || 0);
          }
        }
      });
    } else if (tag == "TOTAL") {
      data.forEach((obj) => {
        for (const key in obj) {
          if (!isNaN(obj[key])) {
            total[key] = (parseInt(total[key]) || 0) + (parseInt(obj[key]) || 0);
          }
        }
      });
    }
    return total;
  }

  // let fullyMappedTees = computeFullyMappedTees(selectedCourse);
  // console.log('selectedCourse || fullyMappedTees: ', selectedCourse, fullyMappedTees);
  // selectedTee && scorecardButtonEnable
  // console.log('Selected Tee & scorecardButtonEnable: ', selectedTee, scorecardButtonEnable);
  useEffect(() => {
    setFullyMappedTees(computeFullyMappedTees(selectedCourse));
  }, [selectedCourse]);

  /*
  useEffect(() => {
    if (!fullyMappedTees.length) {
      cleanUpScoreCard();
    }
  }, [fullyMappedTees])*/

  const scorecardModalRef = useRef(null);

  const showScorecard = () => {
    const modalElement = document.querySelector("#scoreCardModal");
    let modalInstance = window.bootstrap.Modal.getInstance(modalElement);
    if (!modalInstance) {
      modalInstance = new window.bootstrap.Modal(modalElement);
    }
    modalInstance.show();
  };

  const hideScorecard = () => {
    // const modalElement = scorecardModalRef.current;
    const modalElement = document.querySelector("#scoreCardModal");
    let modalInstance = window.bootstrap.Modal.getInstance(modalElement);
    if (!modalInstance) {
      modalInstance = new window.bootstrap.Modal(modalElement);
    }
    modalInstance.hide();
  };

  const computeTimeToPar = () => {
    try {
      let negative = false;
      const totalSeconds = parseInt(watch("minutes")) * 60 + parseInt(watch("seconds"));
      // const timeToPar = totalSeconds - totalData[user?.personalInfo?.parGender + "TimePar"];
      let timeToPar = Math.abs(totalData[user?.personalInfo?.parGender + "TimePar"] - totalSeconds);
      if (totalSeconds < totalData[user?.personalInfo?.parGender + "TimePar"]) {
        negative = true;
      }
      if (timeToPar == 0) return "EVEN";
      let timeToParMinutes = parseInt(timeToPar / 60);
      if (negative) timeToParMinutes = -1 * timeToParMinutes;
      console.log('Time to par minutes: ', timeToPar, totalData[user?.personalInfo?.parGender + "TimePar"]);
      const timeToParSeconds = parseInt(timeToPar % 60);
      return timeToParMinutes + ":" + timeToParSeconds.toString().padStart(2, "0");
    } catch (e) {
      return "Undefined";
    }
  };

  const checkTimeToParNegative = () => {
    try {
      const totalSeconds = parseInt(watch("minutes")) * 60 + parseInt(watch("seconds"));
      const timeToPar = totalSeconds - totalData[user?.personalInfo?.parGender + "TimePar"];
      if (timeToPar < 0) return true;
    } catch (e) {
      return false;
    }
  };

  const caliculatePace = () => {
    // const minutes = watch("minutes");
    // const seconds = watch("seconds");
    // const distance = watch("distance");
    // if (!minutes || !seconds || !distance) return null;
    // const totalSeconds = parseInt(watch("minutes")) * 60 + parseInt(watch("seconds"));
    // const pace = totalSeconds / (watch("distance") * 60);
    // return pace.toFixed(2).toString() + ` ${distanceUnit} per Minute`;
    // const calculatePace = () => {
    const minutes = watch("minutes");
    const seconds = watch("seconds");
    const distance = watch("distance");
    // const distanceUnit = distanceUnit;
    if (!minutes || !seconds || !distance || !distanceUnit) return null;
    const totalMinutes = parseInt(minutes) + parseInt(seconds) / 60;

    const distanceInMiles = distanceUnit === "km" ? distance * 0.621371 : distance;

    const pace = totalMinutes / parseFloat(distanceInMiles);

    const paceMinutes = Math.floor(pace);
    const paceSeconds = Math.round((pace - paceMinutes) * 60);

    const unitLabel = distanceUnit === "km" ? "Min/Km" : "Min/Mile";

    return `${paceMinutes}:${paceSeconds.toString().padStart(2, "0")} ${unitLabel}`;
    // };
  };

  const strokesToPar = watch("strokes") - totalData[user?.personalInfo?.parGender + "StrokePar"];
  const timeToPar = computeTimeToPar();
  const pace = caliculatePace();

  // useEffect(() => {
  //   const pace = caliculatePace();
  // }, [watch("minutes"), watch("seconds"), watch("distance"), distanceUnit]);

  console.log("PACE: ", pace);
  return (
    <>
      <Navbar />
      <div
        id='roundsModeDialog'
        className='mode-page action-dialog'
        role='dialog'
        aria-modal='true'
        aria-labelledby='roundFormHeader'
        tabIndex='0'>
        <h1 id='roundFormHeader' className='mode-page-header'>
          {isNew ? "Add" : "Edit"} Round
        </h1>
        {!isEmpty(errors) && (
          <p id='roundErrorBox' className='alert alert-danger centered'>
            {Object.keys(errors).map((field) => {
              const id = "round" + capitalize(field);
              return (
                <ErrorMessage key={id} id={id}>
                  {validationErrorMap[id]}
                </ErrorMessage>
              );
            })}
          </p>
        )}
        <form id='logRoundForm' className='centered' noValidate onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-3'>
            <label htmlFor='roundDate' className='form-label'>
              Date:
              <input
                id='roundDate'
                className='form-control centered'
                type='date'
                aria-describedby='roundDateDescr'
                {...register("date", {
                  required: true,
                })}
              />
            </label>
            <div id='roundDateDescr' className='form-text'>
              Enter a valid date
            </div>
          </div>
          <div className='d-flex flex-row flex-wrap justify-content-center'>
            <div className='course-container'>
              <label htmlFor='roundCourse' className='form-label'>
                Course:
                <Controller
                  name='course'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, ref, onChange, ...rest } }) => (
                    <AddCourseAutocomplete
                      {...rest}
                      ref={ref}
                      value={value}
                      onChange={(item) => setData(item)}
                      fetchCourseDetailsCallBack={fetchCourseDetailsCallBack}
                    />
                  )}
                />
                <div id='roundCourseDescr' className='form-text mb-2' style={{ alignSelf: "flex-start" }}>
                  Choose a course from the dropdown list
                </div>
              </label>
            </div>
            <div className='tee-container'>
              <label htmlFor='roundTee'>
                Tee:
                <select
                  name='tee'
                  id='tee'
                  className='form-control centered roundTee'
                  onChange={(event) => setSelectedTee(JSON.parse(event.target.value))}
                  disabled={!selectedCourse || fullyMappedTees.length == 0 ? true : false}
                  value={selectedTee ? JSON.stringify(selectedTee) : ""}>
                  {!selectedCourse || fullyMappedTees.length == 0 ? (
                    <option value={null}>{"No Data"}</option>
                  ) : (
                    fullyMappedTees?.map((tee, index) => (
                      <option
                        key={index}
                        value={JSON.stringify(tee)}
                        selected={selectedTee && selectedTee._id === tee.id}>
                        {tee.name}
                      </option>
                    ))
                  )}
                </select>
              </label>
            </div>
          </div>
          <div className='mb-3'>
            <label htmlFor='roundType'>
              Type:
              <select name='roundType' id='roundType' className='form-control centered' {...register("roundType")}>
                <option value='Practice'>Practice</option>
                <option value='Tournament'>Tournament</option>
              </select>
            </label>
          </div>
          <div className='mb-3'>
            <label htmlFor='roundHoles'>
              Holes:
              <select
                name='roundHoles'
                id='roundHoles'
                className='form-control centered'
                {...register("numHoles")}
                //Settting default value to 18
                defaultValue='18'>
                <option value='9'>9</option>
                <option value='18'>18</option>
              </select>
            </label>
          </div>
          {/* <div className='d-flex flex-row justify-content-center'> */}
          {/* <div className='strokes-container'>
              <label htmlFor='roundStrokes'>
                Strokes:
                <input
                  id='roundStrokes'
                  className='form-control centered'
                  type='number'
                  size='3'
                  min='9'
                  max='200'
                  disabled={scorecardButtonEnable ? true : false}
                  aria-describedby='roundStrokesDescr'
                  // value={scorecardButtonEnable ? totalStrokesData?.total['strokes'] : getValues('strokes')}
                  {...register("strokes", {
                    // onBlur: sgsHandler,
                    onChange: sgsHandler,
                    valueAsNumber: true,
                    min: 9,
                    max: 200,
                    required: true,
                  })}
                />
              </label>
            </div> */}

          {/* <div className='scorecard-container'>
              <input
                type='checkbox'
                className='custom-control-input me-1'
                id='scorecardButtonEnable'
                onClick={(e) => {
                  if (e.target.checked) {
                    setScoreCardButtonEnable((prevState) => !prevState);
                    checkStokesComplete();
                    checkTimeComplete(e.target.checked);
                  } else {
                    handleUncheckScorecard(scorecardButtonEnable);
                  }
                  // if (e.target.checked) {
                  //   // setValue("strokes", 0);
                  //   updateGolfRoundMetrics()
                  // } else {
                  //   setHoleByHole([...beforeSaveRoundData])
                  // }
                }}
                disabled={selectedTee ? false : true}
                checked={selectedTee && scorecardButtonEnable ? scorecardButtonEnable : false}
                value={scorecardButtonEnable}
              />
              <label className='custom-control-label me-2' for='scorecardButtonEnable'>
                Hole-By-Hole Data
              </label>
              <button
                type='button'
                className='btn btn-outline-dark'
                disabled={selectedTee && scorecardButtonEnable ? false : true}
                onClick={() => {
                  setBeforeSaveRoundData(holeByHole);
                  setTotalStrokesData((prevValue) => {
                    const clonedState = _.cloneDeep(prevValue);
                    clonedState.total.minutes = getValues("minutes");
                    clonedState.total.seconds = getValues("seconds");
                    clonedState.total.strokes = getValues("strokes");
                    return clonedState;
                  })
                  showScorecard();
                }}>
                <span id='scoreCardDetails' className={`fas fa-table`}></span>
              </button>
            </div> */}

          {fullyMappedTees.length > 0 && (
            <div className='d-flex flex-column align-items-center mb-3'>
              <div className='d-flex align-items-center'>
                <input
                  type='checkbox'
                  className='custom-control-input me-1'
                  id='scorecardButtonEnable'
                  onClick={(e) => {
                    if (e.target.checked) {
                      setScoreCardButtonEnable((prevState) => !prevState);
                      checkStokesComplete();
                      checkTimeComplete(e.target.checked);
                    } else {
                      handleUncheckScorecard(scorecardButtonEnable);
                    }
                    // Additional logic can be uncommented if needed:
                    // if (e.target.checked) {
                    //   // setValue("strokes", 0);
                    //   updateGolfRoundMetrics();
                    // } else {
                    //   setHoleByHole([...beforeSaveRoundData]);
                    // }
                  }}
                  disabled={selectedTee ? false : true}
                  checked={selectedTee && scorecardButtonEnable ? scorecardButtonEnable : false}
                  value={scorecardButtonEnable}
                />
                <label className='custom-control-label me-2' htmlFor='scorecardButtonEnable'>
                  Hole-By-Hole Data
                </label>
              </div>
              <button
                type='button'
                className='btn btn-outline-dark mt-2'
                //added
                // data-bs-target='#scoreCardModal'
                // data-bs-toggle='modal'
                disabled={selectedTee && scorecardButtonEnable ? false : true}
                onClick={() => {
                  setBeforeSaveRoundData(holeByHole);
                  setTotalStrokesData((prevValue) => {
                    const clonedState = _.cloneDeep(prevValue);
                    clonedState.total.minutes = getValues("minutes");
                    clonedState.total.seconds = getValues("seconds");
                    clonedState.total.strokes = getValues("strokes");
                    return clonedState;
                  });
                  showScorecard();
                }}>
                <span id='scoreCardDetails' className={`fas fa-table`}></span>
              </button>
            </div>
          )}

          <div className='mb-3'>
            <label htmlFor='roundStrokes'>
              Strokes:
              <input
                id='roundStrokes'
                className='form-control centered'
                type='number'
                size='3'
                min='9'
                max='200'
                disabled={scorecardButtonEnable ? true : false}
                aria-describedby='roundStrokesDescr'
                {...register("strokes", {
                  onChange: sgsHandler,
                  valueAsNumber: true,
                  min: 9,
                  max: 200,
                  required: true,
                })}
              />
            </label>
          </div>

          {/* </div> */}
          <div id='roundStrokesDescr' className='form-text mb-3'>
            Enter a strokes value between 9 and 200
          </div>
          {!isNaN(strokesToPar) && (
            <div className='centered'>
              <h6 style={{ color: strokesToPar < 0 ? "red" : "black" }}>
                Strokes to par: {strokesToPar == 0 ? "EVEN" : strokesToPar}
              </h6>
            </div>
          )}
          <div className='mb-3'>
            <label htmlFor='roundTime'>
              Time:
              <br />
              <input
                id='roundMinutes'
                type='number'
                size='3'
                aria-describedby='roundTimeDescr'
                min='10'
                max='400'
                // disabled={scorecardButtonEnable}
                style={{ textAlign: "right" }}
                {...register("minutes", {
                  // onBlur: sgsHandler,
                  // onChange: sgsHandler,
                  onChange: () => {
                    clearScorecardTime();
                    setTotalStrokesData((prevData) => {
                      const deepClone = _.cloneDeep(prevData);
                      deepClone.total.minutes = getValues("minutes");
                      return deepClone;
                    });
                  },
                  valueAsNumber: true,
                  min: 10,
                  max: 400,
                  required: true,
                })}
              />
              :
              <input
                id='roundSeconds'
                type='number'
                size='2'
                min='0'
                max='59'
                aria-describedby='roundTimeDescr'
                // disabled={scorecardButtonEnable}
                {...register("seconds", {
                  // onBlur: sgsHandler,
                  onBlur: validateSeconds,
                  onChange: () => {
                    clearScorecardTime();
                    setTotalStrokesData((prevData) => {
                      const deepClone = _.cloneDeep(prevData);
                      deepClone.total.seconds = getValues("seconds");
                      return deepClone;
                    });
                  },
                  min: 0,
                  max: 59,
                  required: true,
                })}
              />
            </label>
            <div id='roundTimeDescr' className='form-text'>
              Enter a minutes value between 10 and 400, and a seconds value between 0 and 59
            </div>
          </div>
          {!(timeToPar == "NaN:NaN") && (
            <div className='centered mb-3'>
              <h6 style={{ color: checkTimeToParNegative() ? "red" : "black" }}>Time to par: {timeToPar}</h6>
            </div>
          )}
          <div className='mb-3'>
            <label htmlFor='roundSGS'>
              Speedgolf Score:
              <br />
              <input
                id='roundSGS'
                className='form-control centered'
                type='text'
                size='6'
                disabled
                {...register("SGS")}
              />
            </label>
          </div>
          <div className='mb-3'>
            <label htmlFor='roundDistance' className='form-label'>
              Distance:
              <input
                id='roundDistance'
                className='form-control centered'
                type='number'
                aria-describedby='roundDistanceDescr'
                step={0.01}
                {...register("distance", {
                  min: distanceUnit === "miles" ? 0.01 : 0.1,
                  max: distanceUnit === "miles" ? 62 : 100,
                  onBlur: formatDistance,
                })}
              />
            </label>
          </div>
          <div className='d-flex justify-content-center mb-2'>
            Miles
            <div className='form-switch'>
              <input
                className='form-check-input centered'
                type='checkbox'
                role='switch'
                checked={distanceUnit !== "miles"}
                onChange={distanceUnitHandler}
                onKeyDown={distanceUnitKeyHandler}
              />
            </div>
            Kilometers
          </div>
          <div className='mb-3'>
            <div id='roundDistanceDescr' className='form-text'>
              Enter a distance value (in miles or km) between 0.01 and 62 miles (100 km)
            </div>
          </div>
          {pace && (
            <div className='mb-3'>
              <h6 className='centered'>Pace: {pace}</h6>
            </div>
          )}
          <div className='mb-3'>
            <label htmlFor='roundNotes'>
              Notes:
              <br />
              <textarea
                id='roundNotes'
                className='form-control'
                aria-describedby='roundNotesDescr'
                rows='6'
                cols='75'
                {...register("notes")} //maxLength is removed.
              />
            </label>
            <div id='roundNotesDescr' className='form-text'>
              Enter optional round notes 
            </div>
          </div>
          <div className='container mt-3 mb-3'>
            <div className='form-group text-center'>
              <div className='custom-control custom-checkbox'>
                <input
                  type='checkbox'
                  className='custom-control-input me-1'
                  id='keepPrivate'
                  onClick={(e) => setValue("keepPrivate", !getValues("keepPrivate"))}
                  {...register("keepPrivate")}
                />
                <label className='custom-control-label' for='keepPrivate'>
                  Keep this round private
                </label>
              </div>
            </div>

            <a
              href='javascript:;'
              ref={popoverRef}
              tabindex='0'
              role='button'
              data-bs-container='body'
              data-bs-toggle='popover'
              data-bs-placement='top'
              data-bs-content='By default, the rounds you log are shared with your buddies. You may also choose to share them with all SpeedScore users through the Account & Profile page. Check this box if you do not wish to share this round with your buddies (or with the public, if you have chosen that option).'>
              What does this mean?
            </a>
          </div>

          <div className='mode-page-btn-container'>
            <button id='roundFormSubmitBtn' className='mode-page-btn action-dialog action-button' type='submit'>
              <span id='roundFormSubmitBtnIcon' className={`fa ${isNew ? "fa-save" : "fa-edit"}`}></span>
              <span id='roundFormSubmitBtnLabel'>
                &nbsp;
                {isNew ? "Add" : "Update"} Round
              </span>
            </button>
            {roundIndex && <button className='btn btn-danger delete-round-btn' type="button" onClick={() => setDeleteDialogId(roundIndex)}>Delete Round</button>}
            <button
              id='roundsModeLogCancelBtn'
              className='mode-page-btn-cancel action-dialog cancel-button'
              type='button'
              onClick={closeLogPage}>
              <span className='fa fa-window-close'></span>
              &nbsp;Cancel
            </button>
          </div>
        </form>
      </div>
      <ScoreCardModal
        setHoleByHole={setHoleByHole}
        beforeSaveRoundData={beforeSaveRoundData}
        selectedCourse={selectedCourse}
        showHoleTimes={showHoleTimes}
        setShowHoleTimes={setShowHoleTimes}
        setRoundStartTime={setRoundStartTime}
        roundStartTime={roundStartTime}
        holeByHole={holeByHole}
        selectedTee={selectedTee}
        user={user}
        outData={outData}
        inData={inData}
        totalData={totalData}
        totalStrokesData={totalStrokesData}
        updateGolfRoundMetrics={updateGolfRoundMetrics}
        cleanUpScoreCard={cleanUpScoreCard}
        scorecardModalRef={scorecardModalRef}
        hideScorecard={hideScorecard}
      />
      <ModalDialog
        isOpen={deleteDialogId}
        title='Delete Round?'
        body='Do you really want to delete that round?'
        actionBtnText='Yes, Delete Round'
        cancelBtnText='No, Cancel'
        close={() => setDeleteDialogId(null)}
        onSubmit={() => deleteRoundHandler(deleteDialogId)}
      />
    </>
  );
};

export default LogRound;
